
// Slow down the background video playback
document.getElementById("video").playbackRate = 0.1;

// Sidebar
const navlogo = document.getElementById("navlogo");
const sidebar = document.getElementById("sidebar");
const menu = document.getElementById("check");

menu.addEventListener("click", () => {
  if (menu.checked) {
    sidebar.classList.add("sb_show");
  } else {
    sidebar.classList.remove("sb_show");
  }
});

navlogo.addEventListener("click", () => {
  menu.checked = false;
  sidebar.classList.remove("sb_show");
});

sidebar.addEventListener("click", () => {
  menu.checked = false;
  sidebar.classList.remove("sb_show");
});


// Carousel
const track = document.querySelector(".carousel_track");
const slides = Array.from(track.children);
const nextButton = document.querySelector(".carousel_button--right");
const prevButton = document.querySelector(".carousel_button--left");
const dotsNav = document.querySelector(".carousel_nav");

const slideWidth = slides[0].getBoundingClientRect().width;

// Automatic slide change every 2 seconds
setInterval(() => {
  nextButton.click();
}, 3000);

// Arrange the slides next to one another
const setSlidePosition = (slide, index) => {
  slide.style.left = slideWidth * index + 'px';
}
slides.forEach(setSlidePosition);

const moveToSlide = (tract, currentSlide, targetSlide) => {
  // move to the next slide
  track.style.transform = `translateX(-${targetSlide.style.left})`;
  currentSlide.classList.remove("current-slide");
  targetSlide.classList.add("current-slide");
}

// when I click right, move slides to the right
nextButton.addEventListener("click", e => {
  const currentSlide = track.querySelector(".current-slide");
  let nextSlide = currentSlide.nextElementSibling;
  if (!nextSlide) {
    track.style.transition = `transform 10ms`;
    nextSlide = track.querySelector(".first-slide");
  } else {
    track.style.transition = `transform 1s ease-in`;
  }
  moveToSlide(track, currentSlide, nextSlide);
})

// when I click left, move slides to the left
prevButton.addEventListener("click", e => {
  const currentSlide = track.querySelector(".current-slide");
  let prevSlide = currentSlide.previousElementSibling;
  if (!prevSlide) {
    prevSlide = track.querySelector(".last-slide");
  }
  console.log("currentSlide", currentSlide);
  console.log("prevSlide", prevSlide);
  moveToSlide(track, currentSlide, prevSlide);
})
